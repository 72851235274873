<template>
    <div v-show="getSpinnerOn" class="blackoutScreen">
        <v-progress-circular
            indeterminate
            color="secondary"
            :size="100"
            :width="15"
        ></v-progress-circular>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

	export default {
		data: function () {
			return {
            }
		},
		computed: {
            ...mapGetters(['getSpinnerOn'])
		},
	}
</script>

<style>
	.blackoutScreen
    {
        position:fixed;
        z-index:10;
        background: rgba(0, 0, 0, 0.4);
        height:100vh;
        width:100vw;
        display:flex;
        justify-content:center;
        align-items:center;
    }
</style>