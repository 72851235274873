//TODO: PROD / DEV DIFFERENCES

let firebaseConfig;

firebaseConfig = {
		
	apiKey: "AIzaSyDDIVVwop37xmRfsFE9vOfK7wIEIYhRIFI",
			authDomain: "large-account-portal.firebaseapp.com",
			databaseURL: "https://large-account-portal-default-rtdb.firebaseio.com",
			projectId: "large-account-portal",
			storageBucket: "large-account-portal.appspot.com",
			messagingSenderId: "470955392051",
			appId: "1:470955392051:web:94df381975c6f33ef73746",
			measurementId: "G-8ZX9E2CNDC"
};

export default firebaseConfig;