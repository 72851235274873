<template>
	<v-app>
		<v-app-bar app color="primary" flat prominent>
			<v-col cols="12">
				<v-row class="specialRowTop">
					<v-col class="d-none d-sm-none d-md-inline ">
						<img src="/img/logo_recrutement_integral.svg" alt="Logo" height="100" width="100"
							style="position: absolute">
					</v-col>
				</v-row>
				<!--
				<v-row class="specialRowBottom">
					<v-col cols="12" class=" d-flex justify-center align-center noPaddingBottom">
						<h2 class="mainTitle">Évaluation du besoin</h2>
					</v-col>
					<v-col cols="12" class=" d-flex justify-center align-center noPaddingTop">
						<h5 class="mainTitle">{{formInfo.Job_Title__c}}</h5>
					</v-col>
				</v-row>
				-->
				<v-row class="specialRowBottom">
					<div class="infoContainer">
						<h2>Évaluation du besoin</h2>
						<p>
							<span class="bold">Employeur: </span>{{formInfo.Employeur__c}}<br/>
							<span class="bold">Poste à combler: </span>{{formInfo.Job_Title__c}}<br/><br/>
							Pour toute question: 450-657-8880
						</p>
					</div>
				</v-row>
			</v-col>
		</v-app-bar>

		<v-main>
			<v-container container--fluid ma-0 pa-0>
				<v-sheet min-height="100vh" v-if="formInfo.Etape_du_Processus__c == 'Révisé par Recrutement Intégral'">
					<v-container class="formContainer" fill-height>
						<v-row justify="center" class="rowTopMargin">
							<v-col cols="12" sm="9">
								<v-form ref="refForm4" v-model="formValid[0]" lazy-validation>

									<v-row justify="center" align="center" class="mb-10">
										<v-btn color="error" @click="formSubmit()">
											Je confirme les informations présentées dans l'évaluation du besoin
										</v-btn>
									</v-row>

									<v-row justify="center" align="center">
										<v-card>
											<iframe id="pdf"
												:src="mainSiteURL + '/apps/apex/EB_PDF?id=' + evaluationDeBesoinId"
												width="1260" height="3500"></iframe>
										</v-card>
									</v-row>
								</v-form>
							</v-col>
						</v-row>
					</v-container>
					<!-- <button @click="clickedFunctionFetch()">Click here to test back-end</button> -->
				</v-sheet>

				<v-sheet class="messageSheet" min-height="100vh" v-if="formInfo.Etape_du_Processus__c != 'Révisé par Recrutement Intégral'">
					<div class="mainDivMessage" v-if="formInfo.Etape_du_Processus__c != '' && formInfo.Etape_du_Processus__c != null">
						<v-card
							elevation="2"
							outlined
						>
							<v-card-title>{{messageInfo.title}}</v-card-title>
							<v-card-text>{{messageInfo.text}}</v-card-text>
						</v-card>
					</div>
				</v-sheet>
			</v-container>
		</v-main>
			<Modal/>
			<Spinner/>
	</v-app>
</template>

<script>
	import firebase from 'firebase/compat/app';
	import firebasefunction from 'firebase/compat/functions';
	import mixins from '@/mixins';
	import Modal from '@/components/Modal.vue';
	import Spinner from '@/components/Spinner.vue';

	export default {
		mixins: [mixins],
		components: {Modal, Spinner},
		data: function () {
			return {
				evaluationDeBesoinId: '',
				errorMessage: null,
				welcomeMessage: 'Welcome to the main page.',
				steps: [
					'Confirmation de l\'évaluation du besoin'
				],
				currentTab: 0,
				formValid: [true, true],
				formInfo: {
					Status__c: '',
					Etape_du_Processus__c: '',
					Job_Title__c: '',
				},
				messageInfo: {title: '', text: ''},
			}
		},
		created() {
			// * id test => a2T6C000000sY8C
			// * Variable qui me permet d'aller chercher mon ID apres evalId
			const queryString = window.location.search
			const urlParams = new URLSearchParams(queryString)
			this.evaluationDeBesoinId = urlParams.get('evalId')

			// * apelle mon API et lui donne l'ID de la page	
			let url =	this.mainSiteURL + "/apps/services/apexrest/evaluation-de-besoin?evalId=" + this.evaluationDeBesoinId;
			this.toggleSpinner(true);

			fetch(url)
				.then(async response => {
					const data = await response.json();

					// check for error response
					if (!response.ok) {
						// get error message from body or default to response statusText
						const error = (data && data.message) || response.statusText;
						return Promise.reject(error);
					}

					this.formInfo = JSON.parse(data);

					//Depending on status
					if(this.formInfo.Etape_du_Processus__c == 'Confirmé par le client')
					{
						this.messageInfo.title = 'Évaluation du besoin – Version finale confirmée';
						this.messageInfo.text = 'Merci d’avoir confirmé la version finale de l’évaluation du besoin. Une proposition d’affaires vous sera présentée sous peu. Au plaisir de collaborer!';
					}
					else
					{
						this.messageInfo.title = 'Erreur';
						this.messageInfo.text = 'Vous ne devriez pas recevoir ce message. Veuillez contacter l\'administrateur.';
					}
				})
				.catch(error => {
					this.errorMessage = error;
					console.error("Une erreur s'est produite :", error);
					this.turnOnModal('error','Erreur', 'Impossible de sauvegarder le formulaire. Veuillez ressayer plus tard: ' + error, 'Ok', false);
				})
				.finally( () => {
					this.toggleSpinner(false);
				});

		},
		methods: {
			//Check if a form doesn't miss a single area and then send the data to the API
			formSubmit() {
				window.scrollTo(0, 0);

				this.formInfo.Etape_du_Processus__c = 'Confirmé par le client';
				this.formInfo.Acceptee_par_Client__c = true;

				const options = {
						method: "POST",
						body: JSON.stringify({type: 'submitForm', payload: this.formInfo})
				};

				this.toggleSpinner(true);

					this.messageInfo.title = 'En cours de confirmation';
					this.messageInfo.text = 'Chargement...';

				// apelle mon API et lui donne l'ID de la page dans une variable
				let url = this.mainSiteURL + "/apps/services/apexrest/evaluation-de-besoin"

				fetch(url, options).then(data => {
						if(typeof data.status == 'undefined' || data.status <200 || data.status >= 400)
						{
							this.turnOnModal('error','Erreur', 'Impossible de confirmer l\'évaluation du besoin. Veuillez ressayer plus tard.', '', true);
						}
						else
						{
							//Success
							this.messageInfo.title = 'Évaluation du besoin – Version finale confirmée';
							this.messageInfo.text = 'Merci d’avoir confirmé la version finale de l’évaluation du besoin. Une proposition d’affaires vous sera présentée sous peu. Au plaisir de collaborer!';
							//this.turnOnModal('primary', 'Succès', 'L\'évaluation du besoin est complétée avec succès.', 'Ok', false);
						}
				})
				.catch(error => {
					this.errorMessage = error;
					console.error("Une erreur s'est produite :", error);
					this.turnOnModal('error','Erreur', 'Impossible de compléter le formulaire. Veuillez ressayer plus tard: ' + error, '', true);
				})
				.finally( () => {
					this.toggleSpinner(false);
				});
			},
		}
	}
</script>

<style>
	.mainTitle {
		color: #f0f0f0;
		text-align: center;
		width: 100%;
		text-transform: uppercase;
		font-weight: normal;
	}

	.v-tab:not(.v-tab--active) {
		color: #f0f0f0 !important;
	}

	.formContainer {
		min-height: 80vh;
		align-items: flex-start !important;
	}

	.bannerSeparator {
		margin-bottom: 3em;
		margin-top: 1em;
		padding: 0;
		position: relative;
	}

	.bannerSeparator>.v-banner__wrapper {
		padding: 0.5em !important;
	}

	.v-banner__text {
		text-align: center;
		color: #f0f0f0;
		height: 23px;
	}

	.rowTopMargin {
		margin-top: 1em !important;
	}

	.specialRowTop {
		padding: 0 1em;
	}

	.specialRowBottom {
		margin-top: 0;
	}

	.theUlList li {
		font-size: 0.9em;
		text-align: left;
	}

	.tableSkills,
	.tableSkills td {
		border: 1px solid #dee2e6;
		border-collapse: collapse;
		padding: 0.25em;
		cursor: pointer;
		user-select: none;
	}

	.tableSkills td.selected {
		background: #33cc33;
	}

		div.infoContainer
	{
		margin-left:7em;
		padding-left:1em;
		border-left:1px solid white;
		position:absolute;
		top:10px;
		max-height:110px;
	}

	div.infoContainer h2
	{
		color:#ee4637;
		font-size:1.25em;
	}

	div.infoContainer p
	{
		font-size:0.7em;
		padding:0;
		margin:0;
		line-height:1em;
		color:white;
	}

	div.infoContainer p > span.bold
	{
		font-weight:bold;
	}


	@media only screen and (max-width:959px)
	{
		div.infoContainer
		{
			border-left:0;
			position:initial;
			margin-left:1em;
		}
	}
</style>