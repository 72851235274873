import Vue from 'vue'
import Vuex from 'vuex';
import store from '@/store';
import firebase from 'firebase/compat/app';
import App from './App.vue'
import firebaseConfig from './config/firebaseConfig';
import vuetify from '@/plugins/vuetify' // path to vuetify export
import VueRouter from 'vue-router'

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(VueRouter);

import { routes } from './router/index.js'
const router = new VueRouter({
	routes,
	mode: 'history',
})

firebase.initializeApp(firebaseConfig);

new Vue({
  el:'#app',
  store,
  vuetify,
  router,
  render: h => h(App),
});
