import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

export default new Vuex.Store({
	state:
	{
		modalOn: false,
		modalInfo: {
			modalType: '',
			modalTitle: '',
			modalText: '',
			modalButton: '',
			modalAlwaysOpen: false,
		},
		spinnerOn: false,
	},
	getters:
	{
		getModalOn(state)
		{
			return state.modalOn;
		},
		getModalInfo: function(state)
		{
			return state.modalInfo;
		},
		getSpinnerOn(state)
		{
			return state.spinnerOn;
		}
	},
	mutations:
	{
		SET_MODAL_ON(state, value)
		{
			state.modalOn = value;
		},
		SET_MODAL_INFO(state, info)
		{
			state.modalInfo = info;
		},
		SET_SPINNER_ON(state, value)
		{
			state.spinnerOn = value;
		}
	},
	actions:
	{
		setModalOn({commit}, value)
		{
			commit('SET_MODAL_ON', value);
		},
		setModalInfo({commit}, value)
		{
			commit('SET_MODAL_ON', true);
			commit('SET_MODAL_INFO', value);
		},
		setSpinnerOn({commit}, value)
		{
			commit('SET_SPINNER_ON', value);
		}
	}
});
