<template>
    <v-dialog v-model="getModalOn" max-width="600" persistent>
        <v-card>
            <v-toolbar
              v-bind:color="getModalInfo.modalType"
              dark
            >
                {{getModalInfo.modalTitle}}
            </v-toolbar>
            <v-card-text>
              <p class="mainText" v-html="getModalInfo.modalText"></p>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                v-if="!getModalInfo.modalAlwaysOpen"
                text
                @click="setModalOn(false)"
              >{{getModalInfo.modalButton}}</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

	export default {
		data: function () {
			return {
            }
		},
		computed: {
            ...mapGetters(['getModalOn', 'getModalInfo'])
		},
        methods: {
            ...mapActions(['setModalOn'])
        },
	}
</script>

<style>
	.mainText
    {
        font-size:1.5em;
        padding:1em;
    }
</style>