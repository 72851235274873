import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
          light: {
            primary: '#003145',
            secondary: '#f0f0f0',
            overTab: '#EE4637',
            error: '#ee4637',
          }
        }
    }
};

export default new Vuetify(opts)