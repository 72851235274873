<template>
	<v-app>
		<v-app-bar app color="primary" flat prominent>
			<v-col cols="12">
				<v-row class="specialRowTop">
					<v-col class="d-none d-sm-none d-md-inline ">
						<img src="/img/logo_recrutement_integral.svg" alt="Logo" height="100" width="100"
							style="position: absolute">
					</v-col>
				</v-row>
				<!-- try new one 
				<v-row class="specialRowBottom">
					<v-col cols="12" class=" d-flex justify-center align-center noPaddingBottom">
						<h2 class="mainTitle">Évaluation du besoin</h2>
					</v-col>
					<v-col cols="12" class=" d-flex justify-center align-center noPaddingTop">
						<h5 class="mainTitle">{{formInfo.Job_Title__c}}</h5>
					</v-col>
				</v-row>
				-->

				<v-row class="specialRowBottom">
					<div class="infoContainer">
						<h2>Évaluation du besoin</h2>
						<p>
							<span class="bold">Employeur: </span>{{formInfo.Employeur__c}}<br/>
							<span class="bold">Poste à combler: </span>{{formInfo.Job_Title__c}}<br/><br/>
							Pour toute question: 450-657-8880
						</p>
					</div>
				</v-row>

			</v-col>
		</v-app-bar>

		<v-main>
			<v-container container--fluid ma-0 pa-0>
				<v-sheet v-if="formInfo.Etape_du_Processus__c == 'Envoyé'" min-height="100vh">

					<v-tabs v-model="currentTab" align-with-title grow background-color="#00384F" color="overTab"
						slider-color="overTab">
						<v-tabs-slider color="overTab"></v-tabs-slider>

						<v-tab color="overTab" v-for="step, index in steps" :key="index">
							{{ step }}
						</v-tab>
					</v-tabs>



					<v-tabs-items v-model="currentTab">


						<!-- Info entreprise start -->
						<v-tab-item :eager="true">
							<v-container class="formContainer" fill-height>
								<v-row justify="center" class="rowTopMargin">
									<v-col cols="12" sm="9">

										<v-banner color="overTab" elevation="2" class="bannerSeparator headline">
											Information sur l'entreprise
										</v-banner>

										<v-form ref="refForm0" v-model="formValid[0]" lazy-validation
											@submit.prevent="formSubmit">
											<v-row>

												<v-col cols="12" md="6">
													<v-text-field v-model="formInfo.Number_of_Employee2__c" maxlength="255"
														label="Nombre d’employés total">
													</v-text-field>
												</v-col>

												<v-col cols="12" md="6">
													<v-text-field v-model="formInfo.Immediate_supervisor_name__c" maxlength="255"
														label="Nom du superviseur immédiat">
													</v-text-field>
												</v-col>

												<v-col cols="12" md="6">
													<v-text-field v-model="formInfo.Employees_in_team2__c" maxlength="255"
														label="Nombre d’employés dans l'équipe">
													</v-text-field>
												</v-col>

												<v-col cols="12" md="6">
													<v-text-field v-model="formInfo.Immediate_supervisor_title__c" maxlength="255"
														label="Titre du superviseur immédiat">
													</v-text-field>
												</v-col>

												<v-col cols="12" md="6">
													<v-text-field v-model="formInfo.Year_founded2__c" maxlength="255"
														label="Année de fondation de l'entreprise">
													</v-text-field>
												</v-col>

												<v-col cols="12" md="6">
													<v-text-field v-model="formInfo.Average_tenure_years2__c" maxlength="255"
														label="Moyenne d’ancienneté des employés">
													</v-text-field>
												</v-col>

											</v-row>

											<v-row justify="center" align="center" class="mt-10 ml-10">

												<v-btn class="ma-4" color="primary" @click="formSubmit(true)">
													Prochaine étape
												</v-btn>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-tab-item>
						<!-- Info entreprise end -->

						<!-- Info poste start -->
						<v-tab-item :eager="true">
							<v-container class="formContainer" fill-height>
								<v-row justify="center" align="center" class="rowTopMargin">
									<v-col cols="12" sm="9">

										<v-form ref="refForm1" v-model="formValid[1]" lazy-validation>


											<v-row justify="center" align="center" class="mb-5 ml-10">
												<v-btn color="error" class="ma-4" @click="formPrecedent()">
													Précédent
												</v-btn>


												<v-btn class="ma-4" color="primary" @click="formSubmit(true)">
													Prochaine étape
												</v-btn>
											</v-row>

											<v-row justify="center" align="center" class="mb-5">
												<div id="errorMsg" style="color:red;" v-if="errorMessage != null">
													Une erreur s'est produite : {{ errorMessage }}
												</div>
											</v-row>

											<v-banner color="overTab" elevation="2" class="bannerSeparator headline">
												Contexte du poste à combler
											</v-banner>

											<v-row>

												<v-col cols="12">
													<v-select :items="formPickList.whyHiringPicklistValues"
														:item-text="'label'" :item-value="'value'"
														v-model="formInfo.Why_Hiring__c" label="Raison du besoin" outlined></v-select>
													<v-textarea v-model="formInfo.Reason_for_hire__c" label="Précisez"
														maxlength="10000"
														outlined rows="3">
													</v-textarea>
												</v-col>

												<v-col cols="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label for="checkboxNotReplacement">Nom de la personne à
																remplacer</label>
															<v-checkbox class="ml-4"
																v-model="formInfo.Not_Replacement__c" label="Aucune"
																name="checkboxNotReplacement"></v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-text-field
																v-model="formInfo.Name_of_the_person_to_replace__c"
																label="Si oui, précisez" v-if="!formInfo.Not_Replacement__c"
																 maxlength="255"
																outlined></v-text-field>
														</v-col>
													</v-row>
												</v-col>


												<v-col cols="12">
													<v-tooltip top>
														<template v-slot:activator="{ on, attrs }">
															<v-textarea v-model="formInfo.No_Contact_List__c"
																label="Employeurs à ne pas solliciter"
																v-bind="attrs" v-on="on"
																maxlength="10000"
																required outlined rows="3"></v-textarea>
														</template>
														<span>Employeurs à ne pas solliciter</span>
													</v-tooltip>
												</v-col>

												<v-col cols="12">
													<v-textarea v-model="formInfo.No_Contact_List2__c"
													maxlength="10000"
														label="Candidats à ne pas solliciter" outlined rows="3">
													</v-textarea>
												</v-col>

												<v-col cols="12" md="6">

												</v-col>

											</v-row>

											<v-banner color="overTab" elevation="2" class="bannerSeparator headline">
												<p>Capacité à attirer et à retenir les talents. Critères distinctifs</p>
											</v-banner>

											<v-row>

												<v-col cols="12">
													<v-textarea v-model="formInfo.Additional_info2__c"
													maxlength="10000"
														label="L'EMPLOI: En quoi le poste est-il
															intéressant et stimulant (Défis du poste, possibilités
															d'avancement, conditions particulières, etc.)" outlined rows="3">
													</v-textarea>
												</v-col>

												<v-col cols="12">
													<v-textarea v-model="formInfo.Additional_info3__c"
													maxlength="10000"
														label="L'EMPLOYEUR: Comment vous distinguez-vous?
															(Réputation, culture, les avantages, leadership des
															gestionnaires, les collègues, ambiance de travail,
															rétention, etc.)" outlined rows="3">
													</v-textarea>
												</v-col>

												<v-col cols="12">
													<v-textarea v-model="formInfo.Aditionnal_info__c"
													maxlength="10000"
														label="VOS COMPÉTITEURS: Dans votre industrie, compétiteurs de talents" outlined rows="3">
													</v-textarea>
												</v-col>

											</v-row>

											<v-banner color="overTab" elevation="2" class="bannerSeparator headline">
												Actions liées au recrutement
											</v-banner>

											<v-row>

												<v-col cols="12" md="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label for="checkboxNotReplacement">Avez-vous posé des
																actions pour combler le poste actuel?</label>
															<v-checkbox class="ml-2"
																v-model="formInfo.No_Recruiting_Actions__c"
																label="Aucune" name="checkboxNotReplacement">
															</v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Recruiting_action_taken__c" v-if="!formInfo.No_Recruiting_Actions__c"
																label="Si oui, énumérez les actions en cours (ex.: affichage, sites d’emploi, agences, recruteurs, etc...)"
																outlined
																maxlength="10000"
																rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>

												<v-col cols="12" md="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label for="checkboxNotReplacement">Résultats obtenus
																jusqu'à présent</label>
															<v-checkbox class="ml-2"
																v-model="formInfo.No_Recruiting_Results__c"
																label="Aucun" name="checkboxNotReplacement">
															</v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Recruiting_action_results__c" v-if="!formInfo.No_Recruiting_Results__c"
																label="Si oui, précisez" outlined rows="3" maxlength="10000"></v-textarea>
														</v-col>
													</v-row>
												</v-col>

												<v-col cols="12" md="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label for="checkboxNotReplacement">Prénom(s), nom(s) des
																candidats non retenus</label>
															<v-checkbox class="ml-2" v-model="formInfo.No_Candidates__c"
																label="Aucun" name="checkboxNotReplacement">
															</v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Candidates_not_Selected__c" v-if="!formInfo.No_Candidates__c"
																label="Si oui, précisez" maxlength="255" outlined rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>
											</v-row>

											<v-row justify="center" align="center" class="mb-10 ml-10">
												<v-btn color="error" class="ma-4" @click="formPrecedent()">
													Précédent
												</v-btn>


												<v-btn class="ma-4" color="primary" @click="formSubmit(true)">
													Prochaine étape
												</v-btn>
											</v-row>

										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-tab-item>
						<!-- Info poste end -->

						<!-- Conditions de travail + équipement start -->
						<v-tab-item :eager="true">
							<v-container class="formContainer" fill-height>
								<v-row justify="center" class="rowTopMargin">
									<v-col cols="12" sm="9">

										<v-form ref="refForm2" v-model="formValid[0]" lazy-validation>

											<v-row justify="center" align="center" class="mb-10 ml-10">
												<v-btn color="error" class="ma-4" @click="formPrecedent()">
													Précédent
												</v-btn>


												<v-btn class="ma-4" color="primary" @click="formSubmit(true)">
													Prochaine étape
												</v-btn>
											</v-row>

											<v-banner color="overTab" elevation="2" class="bannerSeparator headline">
												Informations sur le poste à combler
											</v-banner>

											<v-row>
												<v-col cols="12">
													<v-textarea v-model="formInfo.Daily_tasks__c"
													maxlength="10000"
														label="Tâches et responsabilités (quotidiennes et occasionnelles)"
														outlined rows="3">
													</v-textarea>
												</v-col>

												<v-col cols="12" md="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label for="checkboxNbEmployes">Nombre d'employés à superviser</label>
															<v-checkbox class="ml-2"
																v-model="formInfo.No_Employes_to_Supervise__c"
																label="Aucun" name="checkboxNbEmployes">
															</v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Employes_to_Supervise__c" v-if="!formInfo.No_Employes_to_Supervise__c"
																label="Si oui, précisez"
																outlined
																maxlength="10000"
																rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>
												
											</v-row>

											<v-banner color="overTab" elevation="2" class="bannerSeparator headline">
												Exigences, profil recherché, formations requises
											</v-banner>


											<v-row>
												<v-col cols="12">
													<v-textarea v-model="formInfo.Years_of_experience2__c"
														label="Précisez l’expérience requise"
														maxlength="3000"
														outlined rows="3">
													</v-textarea>
												</v-col>
												<v-col cols="12">
													<v-textarea v-model="formInfo.Software__c"
													maxlength="10000"
														label="Précisez les compétences informatiques et technologiques (locigiels, langage de programmation, etc.) requises"
														outlined rows="3">
													</v-textarea>
												</v-col>

												<v-col cols="12">
													<h3>Précisez les connaissances linguistiques requises</h3>
												</v-col>

												<v-col cols="12" md="6">
													<v-select :items="formPickList.langageFrenchEnglishPicklist"
														:item-text="'label'" :item-value="'value'"
														v-model="formInfo.French_Spoken__c" label="Français oral:"
														outlined></v-select>
												</v-col>
												<v-col cols="12" md="6">
													<v-select :items="formPickList.langageFrenchEnglishPicklist"
														:item-text="'label'" :item-value="'value'"
														v-model="formInfo.French_Written__c" label="Français écrit:"
														outlined></v-select>
												</v-col>


												<v-col cols="12" md="6">
													<v-select :items="formPickList.langageFrenchEnglishPicklist"
														:item-text="'label'" :item-value="'value'"
														v-model="formInfo.English_Spoken__c" label="Anglais oral:"
														outlined></v-select>
												</v-col>
												<v-col cols="12" md="6">
													<v-select :items="formPickList.langageFrenchEnglishPicklist"
														:item-text="'label'" :item-value="'value'"
														v-model="formInfo.English_Written__c" label="Anglais écrit:"
														outlined></v-select>
												</v-col>

												
												<v-col cols="12">
													<v-textarea v-model="formInfo.Additional_Language_Info__c"
														label="Autres précisions sur les connaissances linguistiques (au besoin):"
														outlined
														maxlength="10000"
														rows="3">
													</v-textarea>
												</v-col>


												<v-col cols="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label>Formations requises </label>
															<v-checkbox class="ml-4" v-model="formInfo.No_Education__c"
																label="Aucune ou précisez"></v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Training__c" v-if="!formInfo.No_Education__c"
																label="Si oui, précisez (diplômes, certificats, attestations, titre professionnel, etc.)"
																outlined
																maxlength="10000"
																rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>

												<v-col cols="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label>Aptitudes physiques requises</label>
															<v-checkbox class="ml-4" 
																v-model="formInfo.No_Physical_Aptitude__c"
																label="Aucune ou précisez:"></v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Physical_condition__c"  v-if="!formInfo.No_Physical_Aptitude__c"
																label="Si oui, précisez  (ex.: marcher, soulever des
																charges, se pencher, travailler en hauteur,
																etc.)" maxlength="10000" outlined rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>

												<v-col cols="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label>Code vestimentaire exigé</label>
															<v-checkbox class="ml-4" v-model="formInfo.No_Code_Vestimantaire__c"
																label="Aucun ou précisez"></v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Code_Vestimentaire__c" v-if="!formInfo.No_Code_Vestimantaire__c"
																label="Si oui, précisez (corporatif, ''Business Casual'', decontracté, jeans, bottes de sécurité, etc)"
																maxlength="10000"
																outlined
																rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>

											</v-row>

											<v-banner color="overTab" elevation="2" class="bannerSeparator headline">
												Précisez les compétences et qualités recherchées(choisir un maximum de 7 compétences ou
												qualités)
											</v-banner>

											<div class="table-responsive-sm">
												<table class="tableSkills">
													<tbody>
														<tr>
															<td colspan="4" scope="col" class="qualityTitle">
																<p><strong>Compétences et qualités de base peu importe
																		le poste à combler</strong></p>
															</td>
															<td scope="col" class="qualityTitle">
																<p><strong>Postes professionnels et techniques</strong>
																</p>
															</td>
															<td scope="col" class="qualityTitle">
																<p><strong>Postes de gestion de premier niveau</strong>
																</p>
															</td>
															<td scope="col" class="qualityTitle">
																<p><strong>Postes de haute direction</strong></p>
															</td>
														</tr>
														<tr>
															<td class="qualityTitle">
																<p><strong>Capacité de</strong>
																	<strong>réflexion</strong></p>
															</td>
															<td class="qualityTitle">
																<p><strong>Qualités</strong>
																	<strong>personnelles</strong></p>
															</td>
															<td class="qualityTitle">
																<p><strong>Compétences</strong>
																	<strong>relationnelles</strong></p>
															</td>
															<td class="qualityTitle">
																<p><strong>Compétences</strong>
																	<strong>opérationnelles</strong></p>
															</td>
															<td class="qualityTitle">
																<p><strong>Capacité de</strong>
																	<strong>leadership</strong></p>
															</td>
															<td class="qualityTitle">
																<p><strong>Compétences de gestion</strong></p>
															</td>
															<td class="qualityTitle">
																<p><strong>Compétences stratégiques</strong></p>
															</td>
														</tr>
														<tr v-for="qualityRow, index in formPickList.competencyQualityPicklist" :key="index">
														<td v-for="aQuality in qualityRow" :key="aQuality"
															:class="{'selected': getCompetencyQualityArray.includes(aQuality)}"
															@click="selectOnTable(aQuality)"
														>
															{{aQuality}}
														</td>
													</tr>

													</tbody>
												</table>
											</div>

											<br/><br/>
											<v-row>
												<v-col cols="12">
													<v-row class="specialRowTop">
															<v-col cols="12" class="pa-0 d-flex justify-start align-center">
																<label>Toutes autres exigences requises</label>
																<v-checkbox class="ml-4" v-model="formInfo.No_Autres_Exigences__c"
																	label="Aucune ou précisez"></v-checkbox>
															</v-col>
														</v-row>
														<v-row class="specialRowBottom">
															<v-col cols="12" class="pt-0">
																<v-textarea v-model="formInfo.Autres_Exigences__c" v-if="!formInfo.No_Autres_Exigences__c"
																	label="Si oui, précisez"
																	maxlength="10000"
																	outlined
																	rows="3"></v-textarea>
															</v-col>
													</v-row>
												</v-col>
											</v-row>

											<v-row justify="center" align="center" class="mt-10 ml-10">
												<v-btn color="error" class="ma-4" @click="formPrecedent()">
													Précédent
												</v-btn>


												<v-btn class="ma-4" color="primary" @click="formSubmit(true)">
													Prochaine étape
												</v-btn>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-tab-item>
						<!-- Conditions de travail + équipement end -->

						<!-- Rémunération globale start -->
						<v-tab-item :eager="true">
							<v-container class="formContainer" fill-height>
								<v-row justify="center" class="rowTopMargin">
									<v-col cols="12" sm="9">
										<v-form ref="refForm3" v-model="formValid[0]" lazy-validation>

											<v-row justify="center" align="center" class="mb-10 ml-10">
												<v-btn color="error" class="ma-4" @click="formPrecedent()">
													Précédent
												</v-btn>


												<v-btn class="ma-4" color="primary" @click="formSubmit(true)">
													Prochaine étape
												</v-btn>
											</v-row>

											
											<v-banner color="overTab" elevation="2" class="bannerSeparator headline">
												Conditions de travail
											</v-banner>

											<v-row>

												<v-col cols="12">
													<v-select :items="formPickList.jobTypePicklist" :item-text="'label'"
														:item-value="'value'" v-model="formInfo.Job_Type__c"
														label="Statut du poste" outlined></v-select>
													<v-textarea v-model="formInfo.Job_Type_Details__c"
														label="Précisez le statut du poste (au besoin)"
														maxlength="255"
														outlined rows="3">
													</v-textarea>
												</v-col>

												<v-col cols="12" md="6">
													<v-textarea v-model="formInfo.Schedule__c"
													maxlength="10000"
														label="Horaire de travail (début, fin, durée de la période de repas)"
														outlined rows="3">
													</v-textarea>
												</v-col>

												<v-col cols="12" md="6">
													<v-text-field v-model="formInfo.Hours_per_Week2__c"
														label="Nombre d'heures total par semaine"
														 maxlength="255"
														outlined></v-text-field>
												</v-col>

												<v-col cols="12" md="12">
													<v-textarea v-model="formInfo.Overtime__c"
													maxlength="10000"
														label="Temps supplémentaire. Précisez (fréquence, repris en temps, payé à temps et demi, temps double, etc.)"
														outlined rows="3">
													</v-textarea>
												</v-col>


												<v-col cols="12" md="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label for="checkboxNotReplacement">Télétravail</label>
															<v-checkbox class="ml-4" v-model="formInfo.No_Telework__c"
																label="Aucun ou précisez:" name="checkboxNotReplacement">
															</v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Telework__c" v-if="!formInfo.No_Telework__c"
															maxlength="10000"
																label="" outlined rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>

												<v-col cols="12" md="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label for="checkboxNotReplacement">Déplacement
																requis</label>
															<v-checkbox class="ml-4" v-model="formInfo.No_Travel__c"
																label="Aucun ou précisez:" name="checkboxNotReplacement">
															</v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Travel__c" v-if="!formInfo.No_Travel__c"
															maxlength="10000"
																label="" outlined rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>

												<v-col cols="12" md="12">
													<v-textarea v-model="formInfo.Work_Environment__c"
													maxlength="10000"
														label="Environnement de travail. Précisez (Bureau fermé, aire ouverte, poussière, chaleur, froid, etc.):"
														outlined rows="3">
													</v-textarea>
												</v-col>

											</v-row>

											<v-banner color="overTab" elevation="2" class="bannerSeparator headline">
												Équipements fournis par l'employeur:
											</v-banner>

											<v-row>
												<v-col cols="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<v-checkbox v-model="formInfo.No_Equipment__c" @click="noEquipmentClicked()"
																label="Aucun équipement fourni"
																name="checkboxNotReplacement"></v-checkbox>
														</v-col>
													</v-row>
												</v-col>

												<template v-if="!formInfo.No_Equipment__c">

													<v-col cols="12">
														<v-row class="specialRowTop">
															<v-col cols="12" class="pa-0 d-flex justify-start align-center">
																<v-checkbox v-model="formInfo.Cell_phone__c"
																	label="Téléphone cellulaire"
																	name="checkboxNotReplacement"></v-checkbox>
															</v-col>
														</v-row>
														<v-row class="specialRowBottom">
															<v-col cols="12" class="pt-0">
																<v-textarea v-model="formInfo.Cell_Details__c" v-if="formInfo.Cell_phone__c"
																maxlength="10000"
																	label="Si oui, précisez" outlined rows="3"></v-textarea>
															</v-col>
														</v-row>
													</v-col>

													<v-col cols="12">
														<v-row class="specialRowTop">
															<v-col cols="12" class="pa-0 d-flex justify-start align-center">
																<v-checkbox v-model="formInfo.Laptop__c"
																	label="Ordinateur portable"
																	name="checkboxNotReplacement"></v-checkbox>
															</v-col>
														</v-row>
														<v-row class="specialRowBottom">
															<v-col cols="12" class="pt-0">
																<v-textarea v-model="formInfo.Laptop_Details__c" v-if="formInfo.Laptop__c"
																maxlength="10000"
																	label="Si oui, précisez" outlined rows="3"></v-textarea>
															</v-col>
														</v-row>
													</v-col>

													<v-col cols="12">
														<v-row class="specialRowTop">
															<v-col cols="12" class="pa-0 d-flex justify-start align-center">
																<v-checkbox v-model="formInfo.Uniforms__c"
																	label="Uniforme et équipement de protection individuelle"
																	name="checkboxNotReplacement"></v-checkbox>
															</v-col>
														</v-row>
														<v-row class="specialRowBottom">
															<v-col cols="12" class="pt-0">
																<v-textarea v-model="formInfo.Uniforms_Details__c" v-if="formInfo.Uniforms__c"
																	maxlength="3000" label="Si oui, précisez" outlined rows="3"></v-textarea>
															</v-col>
														</v-row>
													</v-col>

													<v-col cols="12">
														<v-row class="specialRowTop">
															<v-col cols="12" class="pa-0 d-flex justify-start align-center">
																<v-checkbox v-model="formInfo.Car__c" label="Voiture"
																	name="checkboxNotReplacement"></v-checkbox>
															</v-col>
														</v-row>
														<v-row class="specialRowBottom">
															<v-col cols="12" class="pt-0">
																<v-textarea v-model="formInfo.Car_details__c" v-if="formInfo.Car__c"
																maxlength="10000"
																	label="Si oui, précisez" outlined rows="3"></v-textarea>
															</v-col>
														</v-row>
													</v-col>

													<v-col cols="12" class="pt-0">
														<v-textarea v-model="formInfo.Other_equipment__c"
														maxlength="10000"
															label="Autres équipements fournis (au besoin)"
															outlined rows="3">
														</v-textarea>
													</v-col>

												</template>

											</v-row>

											<v-row justify="center" align="center" class="mt-10 ml-10">
												<v-btn color="error" class="ma-4" @click="formPrecedent()">
													Précédent
												</v-btn>


												<v-btn class="ma-4" color="primary" @click="formSubmit(true)">
													Prochaine étape
												</v-btn>
											</v-row>

											
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-tab-item>
						<!-- Rémunération globale end -->

						<!-- Actions liées au recrutement start -->
						<v-tab-item :eager="true">
							<v-container class="formContainer" fill-height>
								<v-row justify="center" class="rowTopMargin">
									<v-col cols="12" sm="9">
										<v-form ref="refForm4" v-model="formValid[0]" lazy-validation>

											<v-row justify="center" align="center" class="ml-10">
												<v-btn color="error" class="ma-4" @click="formPrecedent()">
													Précédent
												</v-btn>


												<v-btn class="ma-4" color="primary" @click="formSubmit(true)">
													Prochaine étape
												</v-btn>
											</v-row>

											<v-banner color="overTab" elevation="2" class="bannerSeparator headline">
												Rémunération globale et avantages
											</v-banner>

											<v-row>
												<v-col cols="6">
													<v-select :items="formPickList.employmentTypePicklist"
														:item-text="'label'" :item-value="'value'"
														v-model="formInfo.Employment_type__c"
														outlined
														label="Type de Rémunération"></v-select>
												</v-col>
											</v-row>

											<v-row>
												<v-col cols="6">
													<v-text-field v-model="formInfo.Minimum_Salary2__c"
														label="Échelle salariale minimum" outlined class="mr-2"
														 maxlength="255"
														 ></v-text-field>
												</v-col>
											</v-row>

											<v-row>
												<v-col cols="6">
													<v-text-field v-model="formInfo.Maximum_Salary2__c"
														label="Échelle salariale supérieure"
														 maxlength="255"
														outlined></v-text-field>
												</v-col>
											</v-row>

											<v-row>
												<v-col cols="6">
													<v-select :items="formPickList.payFrequencyPicklist"
														:item-text="'label'" :item-value="'value'"
														 outlined
														v-model="formInfo.PayFrequency__c" label="Fréquence de la paye"
														></v-select>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="6">
													<v-text-field v-model="formInfo.Precisions_Salaire__c"
														label="Précisions sur l'échelle salariale"
														 maxlength="255"
														outlined></v-text-field>
												</v-col>
											</v-row>


											<v-row>

												<v-col cols="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label for="checkboxNotReplacement">Rémunération indirecte (bonus, partage de profits)</label>
															<v-checkbox class="ml-2" v-model="formInfo.No_Bonus__c"
																label="Aucune ou précisez:" name="checkboxNotReplacement">
															</v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Bonus_Commission__c" v-if="!formInfo.No_Bonus__c"
															maxlength="10000"
																label="" outlined rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>

												<v-col cols="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label for="checkboxNotReplacement">Régime d'épargnes et
																fonds de pension</label>
															<v-checkbox class="ml-2" v-model="formInfo.No_REER__c"
																label="Aucun ou précisez (détails, contribution de l’employeur, délais pour en bénéficier, etc.)" name="checkboxNotReplacement">
															</v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.REER_pension_fund__c"  v-if="!formInfo.No_REER__c"
															maxlength="10000"
																label=""
																outlined
																rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>

												<v-col cols="12" md="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label for="checkboxNotReplacement">Assurances
																collectives</label>
															<v-checkbox class="ml-2" v-model="formInfo.No_Insurance__c"
																label="Aucune ou précisez (détails, contribution de l’employeur, délais pour en bénéficier, etc.)" name="checkboxNotReplacement">
															</v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Insurance__c"  v-if="!formInfo.No_Insurance__c"
															maxlength="10000"
																label=""
																outlined
																rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>

												<v-col cols="12" md="12">
													<v-row class="specialRowTop">
														<v-col cols="12"
															class="pa-0 mt-5 mb-5 d-flex justify-start align-center">
															<label for="checkboxNotReplacement">Vacances</label>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Vacations__c"
															maxlength="10000"
																label="(nombre de semaines, dates obligatoires ou au choix, etc.)"
																outlined
																rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>

												<v-col cols="12" md="12">
													<v-row class="specialRowTop">
														<v-col cols="12" class="pa-0 d-flex justify-start align-center">
															<label for="checkboxNotReplacement">Conciliation travail-vie personnelle</label>
															<v-checkbox class="ml-2" v-model="formInfo.No_Work_Life__c"
																label="Aucune ou précisez (journées maladie, journées familliales, payé, ou non, horaire flexible, etc.)" name="checkboxNotReplacement">
															</v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Work_life_balance__c"  v-if="!formInfo.No_Work_Life__c"
															maxlength="10000"
																label=""
																outlined
																rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>

												<v-col cols="12" md="12">
													<v-row class="specialRowTop">
														<v-col style="width: 100px" cols="12"
															class="pa-0 d-flex justify-start align-center">
															<label for="checkboxNotReplacement"
																class="overflow-hidden">Période(s) de fermeture de
																l'entreprise autres que les journées fériées prescrites
																par la CNESST?</label>
															<v-checkbox class="ml-2" v-model="formInfo.No_Shutdown__c"
																label="Aucune" name="checkboxNotReplacement">
															</v-checkbox>
														</v-col>
													</v-row>
													<v-row class="specialRowBottom">
														<v-col cols="12" class="pt-0">
															<v-textarea v-model="formInfo.Closed_dates__c"  v-if="!formInfo.No_Shutdown__c"
															maxlength="10000"
																label="Si oui, précisez(lesquelles, rénumerées ou non)" outlined rows="3"></v-textarea>
														</v-col>
													</v-row>
												</v-col>

											</v-row>

											
											<v-row justify="center" align="center" class="mt-10 ml-10">
												<v-btn color="error" class="ma-4" @click="formPrecedent()">
													Précédent
												</v-btn>


												<v-btn class="ma-4" color="primary" @click="formSubmit(true)">
													Prochaine étape
												</v-btn>
											</v-row>

										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-tab-item>
						<!-- Action liées au recrutement end -->
						
						<!-- DOCUMENTS start -->
						<v-tab-item :eager="true">
							<v-container class="formContainer" fill-height>
								<v-row justify="center" class="rowTopMargin">
									<v-col cols="12" sm="9">
										<v-form ref="refForm5" v-model="formValid[0]" lazy-validation>

											<v-row justify="center" align="center" class="mt-10 ml-10">
												<v-btn color="error" class="ma-4" @click="formPrecedent()">
													Précédent
												</v-btn>


												<v-btn class="ma-4" color="primary" @click="formSubmit(true)">
													Prochaine étape
												</v-btn>
											</v-row>

											
											<v-banner color="overTab" elevation="2" class="bannerSeparator headline">
												Soumission de documents
											</v-banner>

											<v-row>
												<v-col cols="12" md="12">
													<label>Vous pouvez nous transférer des documents additionnels
														pertinents (organigramme, description de postes, etc.) (facultatif)</label>
													<v-file-input label="Choisir et transmettre un document à la fois" @change="uploadFilesChange($event)">
													</v-file-input>

													<v-card tile v-if="attachmentInfo.length > 0">
														<v-list dense>
															<v-subheader>Fichiers téléchargés</v-subheader>
															<v-list-item v-for="(attachment, index) in attachmentInfo" :key="index">
																<span class="deleteButton" @click="deleteAttachment(attachment.Id, attachment.Name, index)">
																	<v-icon
																		medium
																		color="red darken-2"
																	>
																	mdi-delete
																	</v-icon>
																</span>
																{{attachment.Name}}
															</v-list-item>
														</v-list>
													</v-card>

												</v-col>

											</v-row>


											<v-row justify="center" align="center" class="mt-10 ml-10">
												<v-btn color="error" class="ma-4" @click="formPrecedent()">
													Précédent
												</v-btn>


												<v-btn class="ma-4" color="primary" @click="formSubmit(true)">
													Prochaine étape
												</v-btn>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-tab-item>
						<!-- DOCUMENTS end -->

						<!-- Confirmation start -->
						<v-tab-item :eager="true">
							<v-container class="formContainer" fill-height>
								<v-row justify="center" class="rowTopMargin">
									<v-col cols="12" sm="9">
										<v-form ref="refForm6" v-model="formValid[0]" lazy-validation>

											<v-row justify="center" align="center" class="mb-10">
												<v-btn color="error" class="ma-4" @click="formPrecedent()">
													Précédent
												</v-btn>


												<v-btn class="ma-4" color="primary" @click="formSubmit(true)">
													Envoyer pour validation
												</v-btn>
											</v-row>

											<v-row justify="center" align="center">
												<v-card v-if="currentTab == 6">
													<iframe id="pdf"
														:src="mainSiteURL + '/apps/apex/EB_PDF?id=' + evaluationDeBesoinId"
														width="1000" height="1000"></iframe>
												</v-card>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-tab-item>
						<!-- Action liées au recrutement end -->

					</v-tabs-items>
					<!-- <button @click="clickedFunctionFetch()">Click here to test back-end</button> -->
				</v-sheet>

				<v-sheet class="messageSheet" min-height="100vh" v-if="formInfo.Etape_du_Processus__c != 'Envoyé'">
					<div class="mainDivMessage" v-if="proceedStatus != '' && proceedStatus != null">
						<v-card
							elevation="2"
							outlined
						>
							<v-card-title>{{messageInfo.title}}</v-card-title>
							<v-card-text>{{messageInfo.text}}</v-card-text>
						</v-card>
					</div>
				</v-sheet>	



			</v-container>
		</v-main>
			<Modal/>
			<Spinner/>
	</v-app>
</template>

<script>
	import firebase from 'firebase/compat/app';
	import firebasefunction from 'firebase/compat/functions';
	import Modal from '@/components/Modal.vue';
	import Spinner from '@/components/Spinner.vue';
	import {mapActions} from 'vuex';
	import mixins from '@/mixins';
	import axios from 'axios';

	export default {
		mixins: [mixins],
		data: function () {
			return {
				evaluationDeBesoinId: '',
				errorMessage: null,
				welcomeMessage: 'Welcome to the main page.',
				steps: [
					'Employeur',
					'Contexte du poste',
					'Profil recherché',
					'Conditions de travail',
					'Rémunération globale et avantages',
					'Documents',
					'Confirmation',
				],
				currentTab: 0,
				formValid: [true, true],
				formInfo: {
					Job_Title__c: '',
					Number_of_Employee2__c: '',
					Employees_in_team2__c: '',
					Year_founded2__c: '',
					Immediate_supervisor_name__c: '',
					Immediate_supervisor_title__c: '',
					Average_tenure_years2__c: '',
					Why_Hiring__c: '',
					Reason_for_hire__c: '',
					Not_Replacement__c: false,
					Name_of_the_person_to_replace__c: '',
					No_Contact_List__c: '',
					No_Contact_List2__c: '',
					Aditionnal_info__c: '',
					Additional_info2__c: '',
					Additional_info3__c: '',
					Employes_to_Supervise__c: '',
					No_Employes_to_Supervise__c: '',
					Daily_tasks__c: '',
					Years_of_experience2__c: '',
					Software__c: '',
					French_Spoken__c: '',
					French_Written__c: '',
					English_Spoken__c: '',
					English_Written__c: '',
					No_Education__c: false,
					Training__c: '',
					No_Physical_Aptitude__c: false,
					Physical_condition__c: '',
					Competency_Quality__c: '',
					Job_Type__c: '',
					Job_Type_Details__c: '',
					Schedule__c: '',
					Hours_per_Week2__c: '',
					Overtime__c: '',
					Dress_code__c: '',
					Work_Environment__c: '',
					Telework__c: '',
					No_Telework__c: false,
					Travel__c: '',
					No_Travel__c: false,
					Cell_Details__c: '',
					Cell_phone__c: false,
					Laptop_Details__c: '',
					Laptop__c: false,
					Uniforms_Details__c: '',
					Uniforms__c: false,
					Car_details__c: '',
					Car__c: false,
					Other_equipment__c: '',
					PayFrequency__c: '',
					Minimum_Salary2__c: '',
					Maximum_Salary2__c: '',
					Recruiting_action_taken__c: '',
					No_Recruiting_Actions__c: false,
					Recruiting_action_results__c: '',
					No_Recruiting_Results__c: false,
					Candidates_not_Selected__c: '',
					No_Candidates__c: false,
					Insurance__c: '',
					No_Insurance__c: false,
					Bonus_Commission__c: '',
					No_Bonus__c: false,
					REER_pension_fund__c: '',
					No_REER__c: false,
					Vacations__c: '',
					Work_life_balance__c: '',
					No_Work_Life__c: false,
					Closed_dates__c: '',
					No_Shutdown__c: false,
					No_Equipment__c: false,
					Attachments: [],
					Etape_du_Processus__c: '',
					No_Code_Vestimantaire__c:'',
					Code_Vestimentaire__c:'',
					No_Autres_Exigences__c: '',
					Autres_Exigences__c: '',
					Precisions_Salaire__c: '',
					Additional_Language_Info__c: '',
					Employeur__c: '',
				},
				attachmentInfo: [],
				formPickList: {
					whyHiringPicklistValues: [{
						value: '',
						label: 'Sélectionner'
					}, {
						value: 'Création du poste',
						label: 'Création du poste'
					}, {
						value: 'Départ volontaire',
						label: 'Départ volontaire'
					}, {
						value: 'Congédiement',
						label: 'Congédiement'
					}, {
						value: "Promotion à l'interne",
						label: "Promotion à l'interne"
					},{
						value: 'Autre',
						label: 'Autre'
					}],
					langageFrenchEnglishPicklist: [{
							value: '10',
							label: '10'
						}, {
							value: '9',
							label: '9'
						}, {
							value: '8',
							label: '8'
						},
						{
							value: '7',
							label: '7'
						}, {
							value: '6',
							label: '6'
						}, {
							value: '5',
							label: '5'
						}, {
							value: '4',
							label: '4'
						},
						{
							value: '3',
							label: '3'
						}, {
							value: '2',
							label: '2'
						}, {
							value: '1',
							label: '1'
						}, {
							value: '0',
							label: '0'
						}, {
							value: 'N/D',
							label: 'N/D'
						}
					],
					competencyQualityPicklist: [
						['Capacité d’apprentissage', 'Savoir s’adapter', 'Capacités à travailler en équipe',
							'Être efficace et productif', 'Courage managérial', 'Savoir planifier',
							'Avoir une pensée stratégique'
						],
						['Capacité d’analyse', 'Avoir confiance en lui', 'Savoir bien communiquer verbalement',
							'Être orienté vers les résultats de qualité', 'Savoir mobiliser', 'Sait organiser',
							'Posséder un bon sens de l’anticipation'
						],
						['Prise de décisions de qualité', 'Autonomie et initiative', 'Savoir collaborer',
							'Tolérer l’ambigüité', 'Développer ses collaborateurs',
							'Savoir diriger, informer et optimiser les ressources',
							'Établir et utiliser ses réseaux'
						],
						['Capacité de rendement sous pression', 'Motivé et engagé', 'Savoir s’affirmer',
							'Savoir gérer son temps', 'Savoir gérer les conflits', 'Savoir contrôler',
							'Avoir un bon sens politique'
						],
						['Faire preuve de curiosité', 'Avoir un besoin de réussir',
							'Être un bon citoyen organisationnel', 'Être orienté client',
							'	Reconnaître les problèmes de performance', 'Savoir déléguer et responsabiliser',
							'Savoir gérer le changement'
						],
						['Capacité d’innover', 'Savoir être imputable', 'Humilité et modestie',
							'Savoir gérer les opérations', 'Savoir négocier', 'Promouvoir une vision globale',
							'Faire preuve d’entrepreneurship'
						]
					],
					jobTypePicklist: [{
						value: '',
						label: 'Sélectionner'
					}, {
						value: 'Temporaire',
						label: 'Temporaire'
					}, {
						value: 'Permanent',
						label: 'Permanent'
					},{
						value: 'Travailleur autonome',
						label: 'Travailleur autonome'
					}],
					employmentTypePicklist: [{
						value: '',
						label: 'Sélectionner'
					}, {
						label: 'Salaire Annuel',
						value: 'Permanent'
					}, {
						label: 'Taux Horaire',
						value: 'Hourly'
					}],
					Employment_type__c: '',
					payFrequencyPicklist: [{
						value: '',
						label: 'Sélectionner'
					}, {
						value: 'Hebdomadaire',
						label: 'Hebdomadaire'
					}, {
						value: 'Aux deux semaines',
						label: 'Aux deux semaines'
					}, {
						value: 'Mensuelle',
						label: 'Mensuelle'
					}, ],
				},

				rules: {
					generalRequired: [v => !!v || 'Le champ est requis']
				},
				//Individual property so we can watch
				proceedStatus: '',
				messageInfo: {title: '', text: ''},
			}
		},
		components: {
			Modal,
			Spinner,
		},
		computed: {
			//Since a property of an object will be undefined on load, we use a computer prop as a workaround.
			//(it's a condition for a class in the table.)
			getCompetencyQualityArray: function()
			{

				let arrayToReturn = null;
				if(typeof this.formInfo.Competency_Quality__c == 'undefined' || this.formInfo.Competency_Quality__c == '')
				{
					this.formInfo.Competency_Quality__c = '';
					arrayToReturn = [];
				}
				else
				{
					arrayToReturn = this.formInfo.Competency_Quality__c.split(";");
				}
				return arrayToReturn;
			},
		},
		created() {
			// id test => a2T6C000000sY8C

			//Spinner on
			this.toggleSpinner(true);

			// Variable qui me permet d'aller chercher mon ID apres evalId
			const queryString = window.location.search
			const urlParams = new URLSearchParams(queryString)
			this.evaluationDeBesoinId = urlParams.get('evalId')
			// apelle mon API et lui donne l'ID de la page	
			let url = this.mainSiteURL + "/apps/services/apexrest/evaluation-de-besoin?evalId=" + this.evaluationDeBesoinId;

			fetch(url)
				.then(async response => {
					const data = await response.json();
	
					// check for error response
					if (!response.ok) {
						// get error message from body or default to response statusText
						const error = (data && data.message) || response.statusText;
						return Promise.reject(error);
					}

					this.formInfo = JSON.parse(data);

					//In case we have file attachments
					if(typeof this.formInfo.Attachments != 'undefined')
					{
						this.attachmentInfo = this.formInfo.Attachments.records;

						//Delete this prop for eventual 
						delete this.formInfo.Attachments;
					}
					
					//For the watcher
					this.proceedStatus = this.formInfo.Etape_du_Processus__c;

				})
				.catch(error => {
					this.errorMessage = error;
					console.error("Une erreur s'est produite :", error);
					this.turnOnModal('error','Erreur',"Une erreur fatale s'est produite: <br/>" + error, '', true);
					document.getElementById('errorMsg').style;
					
				})
				.finally(()=>{
					this.toggleSpinner(false);
				});

			const getValidation = urlParams.get('validationDocument')

		},
		watch: {
			/*
			currentTab(newValue, oldValue)
			{
				//Going back is ok, not the rest though
				if(newValue - oldValue > 0)
				{

					//Need to loop through sections and validate each one, consequentially. 
					//Ex: jump from 1 to 4, verify 1,2,3 before going to 4. If it fails at 3, user stays at 3 with a message.
					let mistakeIndex = -1;

					for(let i=oldValue; i < newValue && mistakeIndex == -1; i++)
					{						
						if(!this.$refs["refForm" + i].validate())
						{
							mistakeIndex = i;
						}
					}

					if(mistakeIndex > -1)
					{
						this.currentTab = mistakeIndex;
						this.turnOnModal('warning','Avertissement', 'Veuillez remplir cette étape avant de pouvoir avancer.', 'Ok', false);
					}

				}

			},
			*/
			proceedStatus(newValue, oldValue)
			{
				switch(newValue)
				{
					case 'En Attente':
						this.messageInfo.title = 'Impossible de remplir le formulaire';
						this.messageInfo.text = 'L\'état du formulaire est toujours en attente.';
					break;
					case 'Formulaire complété et soumis':
						this.messageInfo.title = 'Merci pour votre collaboration!';
						this.messageInfo.text = 'L’évaluation de votre besoin a été envoyée avec succès. Un spécialiste en recrutement communiquera avec vous sous peu.';
					break;
					case 'Révisé par Recrutement Intégral':
					case 'Confirmé par le client':
						this.proceedStatus = '';
						//Send to confirmation
						this.$router.push({ name: "Confirmation", query: {evalId: this.$route.query.evalId}});
					break;
				}
			}
		},
		methods: {
			/*
			//Temp TLHsatRe3nH2GVSdxW/L2Q== is a0O6C000001dWam
			clickedFunctionFetch() {
				let callFirebase = firebase.functions().httpsCallable('customRestAPIRI');
				callFirebase({
						typeOfAction: 'obtainAccessCode',
						payload: {
							ebId: 'TLHsatRe3nH2GVSdxW/L2Q==',
							accessCode: '9676',
						}
					})
					.then((result) => {
						console.log('console part then', result);
					}).catch(err => {
						console.log('catch part', err);
					}).finally(() => {
						//Spinner off
						console.log('finally part');
					});
			},
			*/
			formPrecedent() {
				this.formSubmit(false);
			},
			//Check if a form doesn't miss a single area and then send the data to the API
			formSubmit(goingForward) {
				/*
				// For now, simple validation
				let outcome = this.$refs["refForm" + this.currentTab].validate();

				if (outcome) {
					*/
				this.toggleSpinner(true);
				if(goingForward)
				{
					this.currentTab++;
				}
				else
				{
					this.currentTab--;
				}
				window.scrollTo(0, 0);

				//If tab # 6, we do the final submit.
				if(this.currentTab == 7)
				{
					this.formInfo.Etape_du_Processus__c = 'Formulaire complété et soumis';
				}

				const options = {
					method: "POST",
					body: JSON.stringify({type: 'submitForm', payload: this.formInfo})
				}
				// apelle mon API et lui donne l'ID de la page dans une variable
				let url = this.mainSiteURL + "/apps/services/apexrest/evaluation-de-besoin"

				fetch(url, options).then(data => {
					if(typeof data.status == 'undefined' || data.status <200 || data.status >= 400)
					{
						this.turnOnModal('error','Erreur', 'Impossible de sauvegarder le formulaire. Veuillez ressayer plus tard.', 'Ok', false);
					}
					else
					{
						if(this.currentTab == 7)
						{
							//Success
							this.proceedStatus = 'Formulaire complété et soumis';
							//this.turnOnModal('primary', 'Succès', 'Le formulaire a été envoyé et complété avec succès.', 'Ok', false);
						}
						
					}
					this.toggleSpinner(false);
				})
					/*
				}
				else
				{
					this.turnOnModal('warning','Avertissement', 'Veuillez remplir cette étape avant de pouvoir avancer.', 'Ok', false);
				}
				*/
			},
			//Selected an element from the table
			selectOnTable(choice) 
			{
				//Get it only once
				let theCompetencyQualityArray = this.getCompetencyQualityArray;

				//Index of the selected quality
				var indexOfChoice = theCompetencyQualityArray.indexOf(choice);

				//If not found - then we could add it
				if (indexOfChoice == -1)
				{
					//Push only if less than 7
					if (theCompetencyQualityArray.length < 7) 
					{
						theCompetencyQualityArray.push(choice);
					} 
					else
					{
						this.turnOnModal('warning','Avertissement',"Vous ne pouvez pas ajouter plus de 7 items", 'Ok', false);
					}
				} 
				else 
				{
					//It was found - then we must remove it
					theCompetencyQualityArray.splice(indexOfChoice, 1);
				}

				//In any evenet, put it back into a string
				this.formInfo.Competency_Quality__c = theCompetencyQualityArray.join(";");	

				//Trigger dom update - property of an object won't trigger the update otherwise.
				let tempVar = this.formInfo;
				this.formInfo = null;
				this.formInfo = tempVar;
			},
			//No equipment furnished checkbox clicked
			noEquipmentClicked()
			{
				//If on, remove the rest
				if(this.formInfo.No_Equipment__c)
				{
					this.formInfo.Cell_phone__c = false;
					this.formInfo.Cell_Details__c = '';

					this.formInfo.Laptop__c = false;
					this.formInfo.Laptop_Details__c = '';

					this.formInfo.Uniforms__c = false;
					this.formInfo.Uniforms_Details__c = '';

					this.formInfo.Car__c = false;
					this.formInfo.Car_details__c = '';

					this.formInfo.Other_equipment__c = '';
				}
			},
			//Creating the base64
			filetoBase64: function(file, callback)
			{
				var reader = new FileReader();
						reader.onload = function() {
							var fileContents = reader.result;
							var base64Mark = 'base64,';
							var dataStart = fileContents.indexOf(base64Mark) + base64Mark.length;
							fileContents = fileContents.substring(dataStart);
							callback(null, fileContents);
						}
						reader.readAsDataURL(file);
			},
			//Uploading files change
			uploadFilesChange(event)
			{
					//If undefined - file was removed (clicked on x)
					if(typeof event != 'undefined' && event != null)
					{
						var file = event;
						let currentContext = this;

						//Check file size, must be less than 4 mb
						if(file.size > 2154496)						
						{
							this.turnOnModal('error','Erreur', 'Impossible de télécharger le fichier. La taille maximale permise est de 2 MO.', 'Ok', false);
						}
						else
						{
							this.toggleSpinner(true);
							this.filetoBase64(file, function(err, content)
							{
								// apelle mon API et lui donne l'ID de la page dans une variable
								let url = currentContext.mainSiteURL + "/apps/services/apexrest/evaluation-de-besoin";

								axios.post(url, {type: 'fileUpload',
										fileName: file.name,
										fileContent: content,
										parentID: currentContext.evaluationDeBesoinId}, {
									headers: {'Content-Type': 'application/json'}
								})
								.then((response) => 
								{
									currentContext.attachmentInfo.push(JSON.parse(response.data));
									currentContext.turnOnModal('primary','Fichier', 'Votre fichier a été téléchargé avec succès.', 'Ok', false);
									
								})
								.catch((error) => 
								{
									currentContext.turnOnModal('error','Erreur', 'Impossible de télécharger le fichier. Veuillez ressayer plus tard.', 'Ok', false);
									console.log('post error', error);
								})
								.finally(()=>
								{
									currentContext.toggleSpinner(false);
								});
							});
						}
						
						
						
					}
			},
			//Delete the current attachment
			deleteAttachment(attachmentId, name, index)
			{
				let currentContext = this;
				currentContext.toggleSpinner(true);

				let url = this.mainSiteURL + "/apps/services/apexrest/evaluation-de-besoin";
	
				axios.post(url, {type: 'fileDelete', fileId: attachmentId }, { headers: {'Content-Type': 'application/json'}})
				.then((response) => 
				{
					let operationOutcome = response.data;

					if(operationOutcome != null && operationOutcome == 'deleted')
					{
						currentContext.attachmentInfo.splice(index,1);
						currentContext.turnOnModal('primary','Fichier', 'Le fichier a été supprimé avec succès.', 'Ok', false);
					}
					else
					{
						currentContext.turnOnModal('error','Erreur', 'Impossible de télécharger le fichier. Veuillez ressayer plus tard2.', 'Ok', false);
					}				
					
				})
				.catch((error) => 
				{
					currentContext.turnOnModal('error','Erreur', 'Impossible de télécharger le fichier. Veuillez ressayer plus tard.', 'Ok', false);
					console.log('post error', error);
				})
				.finally(()=>
				{
					currentContext.toggleSpinner(false);
				});
				

			},
		}
	}
</script>

<style>
	.mainTitle {
		color: #f0f0f0;
		text-align: center;
		width: 100%;
		text-transform: uppercase;
		font-weight: normal;
	}

	.v-tab:not(.v-tab--active) {
		color: #f0f0f0 !important;
	}

	.formContainer {
		min-height: 80vh;
		align-items: flex-start !important;
	}

	.bannerSeparator {
		margin-bottom: 3em;
		margin-top: 1em;
		padding: 0;
		position: relative;
	}

	.bannerSeparator>.v-banner__wrapper {
		padding: 0.5em !important;
	}

	.v-banner__text {
		text-align: center;
		color: #f0f0f0;
		height: 23px;
	}

	.rowTopMargin {
		margin-top: 1em !important;
	}

	.specialRowTop {
		padding: 0 1em;
	}

	.specialRowBottom {
		margin-top: 0;
	}

	.theUlList li {
		font-size: 0.9em;
		text-align: left;
	}

	.tableSkills,
	.tableSkills td {
		border: 1px solid #dee2e6;
		border-collapse: collapse;
		padding: 0.25em;
		cursor: pointer;
		user-select: none;
	}

	.tableSkills td.selected {
		background: #33cc33;
	}

	.tableSkills td.qualityTitle
	{
		background:gray;
	}

	.deleteButton
	{
		display:block;
		margin-right:1em;
		cursor:pointer;
	}

	.messageSheet
	{
		background:green;
		display:flex;
		justify-content:center;
		align-items:flex-start;
	}

	.mainDivMessage
	{
		min-width:500px;
		margin-top:10%;
	}

	.noPaddingBottom
	{
		padding-bottom:0;
	}

	.noPaddingTop
	{
		padding-top:0;
	}

	div.infoContainer
	{
		margin-left:7em;
		padding-left:1em;
		border-left:1px solid white;
		position:absolute;
		top:10px;
		max-height:110px;
	}

	div.infoContainer h2
	{
		color:#ee4637;
		font-size:1.25em;
	}

	div.infoContainer p
	{
		font-size:0.7em;
		padding:0;
		margin:0;
		line-height:1em;
		color:white;
	}

	div.infoContainer p > span.bold
	{
		font-weight:bold;
	}


	@media only screen and (max-width:959px)
	{
		div.infoContainer
		{
			border-left:0;
			position:initial;
			margin-left:1em;
		}
	}

</style>