//Functions that are shared between different components
//Vue takes care of double import errors
import {mapActions} from 'vuex';

export default{
    data: function () {
        return {
            //mainSiteURL: 'https://recrutementintegral--sandboxic.sandbox.my.salesforce-sites.com',
            mainSiteURL:'https://recrutementintegral.my.salesforce-sites.com'
        }
    },
    methods: {
        ...mapActions(['setModalInfo', 'setModalOn','setSpinnerOn']),
        turnOnModal: function(type, title, text, buttonText, alwaysOpen)
        {
            this.setModalInfo({
                modalType: type,
                modalTitle: title,
                modalText: text,
                modalButton: buttonText,
                modalAlwaysOpen: alwaysOpen,
            });
        },
        toggleSpinner: function(value)
        {
            this.setSpinnerOn(value);
        }
    },
}