import Root from "./Root"
import Home from '../pages/Home.vue'
import Confirmation from '../pages/Confirmation.vue'
import NotFound from '../pages/NotFound.vue'

export const routes = [
	{
		path:'/',
		component: Root,
		children:[
			{
				path:'',
				name: 'Évaluation de besoin',
				component: Home
			},
			{
				path:'/confirmation',
				name: 'Confirmation',
				component: Confirmation
			},
		]
	},
	{
		path:'*',
		name: 'NotFound',
		component: NotFound
	}
];